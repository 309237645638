import { ZonedDateTime, Duration, convert } from '@js-joda/core'
import timestampService from '../services/timestamps'
import timerService from '../services/timer'
import location from '../util/location'

const saveTimestamp = (state) => {
  const timestamp = {
    ...state,
    start: convert(state.start).toDate(),
    end: convert(ZonedDateTime.now()).toDate(),
    duration: Duration.between(state.start, ZonedDateTime.now())
  }

  timestampService.createNew(timestamp)
}

const timerReducer = (state = {}, action) => {
  switch (action.type) {
  case 'START_TIMER': {
    state.start && saveTimestamp(state)
    const timer = {
      start: ZonedDateTime.now(),
      task: action.task,
    }

    if (location.isAvailable()) {
      timer.location = {
        ...location.getCurrent()
      }
    }

    timerService.start(timer)
    return timer
  }
  case 'STOP_TIMER': {
    saveTimestamp(state)
    timerService.stop()
    return {}
  }
  case 'SET_TIMER': {
    return action.data
  }
  }
  return state
}

export const startTimer = task => {
  return {
    type: 'START_TIMER',
    task,
  }
}

export const stopTimer = () => {
  return {
    type: 'STOP_TIMER'
  }
}

export const setTimer = (data) => {
  return {
    type: 'SET_TIMER',
    data
  }
}

export default timerReducer