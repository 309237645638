import React from 'react'
import { LocalTime, DateTimeFormatter, Duration } from '@js-joda/core'
import { useSelector, useDispatch } from 'react-redux'
import { setTimer, stopTimer } from '../reducers/timerReducer'
import timerService from '../services/timer'
import { Typography, Button, Box, Grid } from '@mui/material'
import { grey } from '@mui/material/colors'
import { GlobalHotKeys } from 'react-hotkeys'

const preventDefaultHandlers = (handlers) => {
  const newHandlers = {}
  for (const [action, handler] of Object.entries(handlers)) {
    newHandlers[action] = (event) => {
      if (event) {
        event.preventDefault()
      }
      handler()
    }
  }
  return newHandlers
}

const TimerNotStarted = () => {
  const style = {
    textAlign: 'center',
  }
  return (
    <Typography style={style}>Click on a task below to start timer</Typography>
  )
}

const TimerStarted = ({ time }) => {
  const style = {
    textAlign: 'center',
  }
  const timer = useSelector(state => state.timer)

  const taskDuration = () => {
    const duration = Duration.between(timer.start,time)

    return LocalTime
      .ofSecondOfDay(duration.seconds() < 0 ? 0 : duration.seconds())
      .format(DateTimeFormatter.ofPattern('HH:mm:ss'))
  }

  return (
    <Box style={style}>
      <Typography>
        {timer.task.name + ' '}
        started at
        {' ' + timer.start.format(DateTimeFormatter.ofPattern('HH:mm'))}
      </Typography>
      <Typography variant='h3'>{' ' + taskDuration()}</Typography>
    </Box>
  )
}

const TimeDisplay = ({ time }) => {
  const dispatch = useDispatch()
  const timer = useSelector(state => state.timer)

  const stop = async () => {
    const serverTimer = await timerService.get()

    if (serverTimer.start) {
      dispatch(setTimer(serverTimer))
      dispatch(stopTimer())
    }
  }

  const keyMap = {
    STOP: 'space',
  }

  const handlers = preventDefaultHandlers({
    STOP: stop
  })

  return (
    <Box sx={{
      position: 'fixed',
      zIndex: 1,
      top: '45px',
      width: '100%',
    }}
    backgroundColor={grey[200]}
    padding={2}>
      <Grid container spacing={2}>
        <Grid sx={{ width: 'fit-content', display: { xs: 'none', sm: 'block' } }} item sm={3} md={2}>
          <Typography variant='h1'>
            {time.format(DateTimeFormatter.ofPattern('HH:mm'))}
          </Typography>
        </Grid>
        <Grid item xs={8} sm={7} md={8}>
          {timer.start
            ? <TimerStarted time={time}/>
            : <TimerNotStarted />
          }
        </Grid>
        <Grid item xs={4} sm={2} md={2}>
          <Button
            variant='contained'
            color='error'
            disabled={timer.start === undefined}
            sx={{ width: '100%', height: '100%' }}
            onClick={() => stop()}>
              Stop
            <GlobalHotKeys keyMap={keyMap} handlers={handlers}/>
          </Button>

        </Grid>
      </Grid>
    </Box>
  )
}

export default TimeDisplay