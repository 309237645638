
const localStorageId = 'loggedT-TimerAppUser'

export const setUser = (data) => {
  return async dispatch => {
    dispatch({
      type: 'SET_USER',
      data
    })
  }
}

export const logoutUser = () => {
  return async dispatch => {
    window.localStorage.removeItem(localStorageId)
    dispatch({
      type: 'UNSET_USER'
    })
  }
}

const userReducer = (state = {}, action) => {
  switch (action.type) {
  case 'SET_USER': {
    return action.data
  }
  case 'UNSET_USER': {
    return {}
  }
  default: return state
  }
}

export default userReducer