
let config

export const setConfig = (newConfig) => {
  config = newConfig
}

export const getConfig = () => {
  return config
}
