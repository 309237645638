import React, { useState } from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import TaskList from './components/TaskList'
import Banner from './components/Banner'
import { initializeTasks } from './reducers/taskReducer'
import TimeDisplay from './components/TimeDisplay'
import { ZonedDateTime, nativeJs } from '@js-joda/core'
import { setTimer } from './reducers/timerReducer'
import timerService from './services/timer'
import settingsService from './services/settings'
import { Box, Divider } from '@mui/material'
import SignIn from './components/SignIn'
import { setUser } from './reducers/userReducer'
import { setFilter } from './reducers/appReducer'
import { setConfig } from './util/config'
import Report from './components/Report'
import { views } from './reducers/appReducer'
import { io } from 'socket.io-client'
import location from './util/location'

const localStorageId = 'loggedT-TimerAppUser'

const App = () => {
  const [time, setTime] = useState(ZonedDateTime.now())
  const dispatch = useDispatch()
  const user = useSelector(state => state.user)
  const app = useSelector(state => state.app)

  const tick = () => {
    setTime(ZonedDateTime.now())
  }

  useEffect(() => {
    const loggedUserJSON = window.localStorage.getItem(localStorageId)
    const interval = setInterval(() => tick(), 1000)

    if (loggedUserJSON) {
      const userData = JSON.parse(loggedUserJSON)
      const socket = io({ auth: { token: userData.config.headers.Authorization.split(' ')[1] } })

      setConfig(userData.config)
      dispatch(setUser(userData))
      dispatch(initializeTasks())
      location.isAvailable() // Request location permission from user and populate location

      socket.on('timer', (data) => {
        if (data.start) {
          data.start = ZonedDateTime.from(nativeJs(new Date(data.start)))
        }

        dispatch(setTimer(data))
      })

      socket.on('settings', (data) => {
        if (data.filter) {
          dispatch(setFilter(data.filter))
        }
      })

      timerService.get().then(timer => {
        dispatch(setTimer(timer))
      })
      settingsService.get().then(settings => {
        dispatch(setFilter(settings.filter))
      })

    }
    return () => clearInterval(interval)
  }, [])

  return (
    <Box>
      {user.name
        ?
        <Box>
          <Banner />
          {app.view === views.timer
            ?
            <Box>
              <TimeDisplay time={time} />
              <Divider variant='middle' />
              <TaskList />
            </Box>
            :
            <Report />
          }
        </Box>
        :
        <SignIn />
      }
    </Box>
  )
}



export default App