import { React, useState } from 'react'
import { Autocomplete, Dialog, DialogActions, DialogContentText, DialogTitle, DialogContent, TextField, Avatar, Grid, Button, Menu, MenuItem, Toolbar, AppBar, Typography } from '@mui/material'
import { grey, blue } from '@mui/material/colors'
import FilterListIcon from '@mui/icons-material/FilterList'
import { useDispatch, useSelector } from 'react-redux'
import { logoutUser } from '../reducers/userReducer'
import { setFilter, setView, views } from '../reducers/appReducer'

const Banner = () => {
  const [anchorElUser, setAnchorElUser] = useState(null)
  const [prevFilter, setPrevFilter] = useState([])
  const [filterDialogVisible, setFilterDialogVisible] = useState(false)
  const dispatch = useDispatch()
  const user = useSelector(state => state.user)
  const filter = useSelector(state => state.app.filter)

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  const handleLogout = () => {
    dispatch(logoutUser())
    handleCloseUserMenu()
  }

  const handleTimerClick = () => {
    dispatch(setView(views.timer))
  }

  const handleReportClick = () => {
    dispatch(setView(views.report))
  }

  const handleFilterClick = () => {
    setPrevFilter(filter)
    setFilterDialogVisible(true)
  }

  const handleDialogClose = (event) => {
    setFilterDialogVisible(false)
    if (event.target.innerText === 'CANCEL') {
      dispatch(setFilter(prevFilter))
    }
  }

  const handleFilterChange = (event, value) => {
    dispatch(setFilter(value))
  }

  const stringAvatar = (name) => {
    return {
      sx: {
        bgcolor: grey[300],
        color: blue[700]
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    }
  }

  return (
    <AppBar position='fixed' sx={{ top: 0 }}>
      <Toolbar variant='dense'>
        <Grid container>
          <Typography sx={{ mr: 2 }} variant="h6" color="inherit" component="div">
        T-Timer
          </Typography>
          <Button sx={{ color: 'white' }} onClick={handleTimerClick}>
            Timer
          </Button>
          <Button sx={{ color: 'white' }} onClick={handleReportClick}>
            Report
          </Button>
        </Grid>
        <Button
          sx={filter.length === 0 ? { color: 'white' } : { color: 'yellow' } }
          onClick={handleFilterClick}
        >
          <FilterListIcon />
        </Button>
        <Button sx={{ color: 'white' }} onClick={handleOpenUserMenu}>
          <Avatar {...stringAvatar(user.name)}/>
        </Button>
        <Menu
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          <MenuItem key='logout' onClick={handleLogout}>
            <Typography textAlign="center">logout</Typography>
          </MenuItem>
        </Menu>

        <Dialog open={filterDialogVisible} onClose={handleDialogClose}>
          <DialogTitle>Filter</DialogTitle>
          <DialogContent>
            <DialogContentText>
            To filter tasks, please enter labels to be included in the view.
            </DialogContentText>
            <Autocomplete
              multiple
              autoComplete
              autoHighlight
              options={user.labels}
              value={filter}
              onChange={handleFilterChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  autoFocus
                  margin='dense'
                  label='Labels'
                  fullWidth
                  variant='standard'
                />
              )}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose}>Cancel</Button>
            <Button onClick={handleDialogClose}>Filter</Button>
          </DialogActions>
        </Dialog>

      </Toolbar>
    </AppBar>
  )
}

export default Banner