import axios from 'axios'
import { getConfig } from '../util/config'

const baseUrl = '/api/report'

const getOTL = async (period) => {
  const response = await axios.post(baseUrl + '/otl', period, getConfig())
  return response.data
}

export default {
  getOTL,
}
