import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import { createStore, combineReducers } from 'redux'
import { applyMiddleware } from 'redux'
import taskReducer from '../reducers/taskReducer'
import timerReducer from '../reducers/timerReducer'
import userReducer from '../reducers/userReducer'
import appReducer from '../reducers/appReducer'

const reducer = combineReducers({
  tasks: taskReducer,
  timer: timerReducer,
  user: userReducer,
  app: appReducer
})

const store = createStore(
  reducer,
  composeWithDevTools(
    applyMiddleware(thunk)
  ))

export default store
