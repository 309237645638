import axios from 'axios'
import { getConfig } from '../util/config'

const baseUrl = '/api/tasks'

const getAll = async () => {
  const response = await axios.get(baseUrl, getConfig())
  return response.data
}

const createNew = async (task) => {
  const response = await axios.post(baseUrl, task, getConfig())
  return response.data
}

export default {
  getAll,
  createNew,
}
