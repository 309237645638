import React, { useEffect, useState } from 'react'
import { Box, TextField } from '@mui/material'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import MobileDatePicker from '@mui/lab/MobileDatePicker'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import { setDate, startOfDay, startOfTomorrow }  from 'date-fns'
import reportService from '../services/report'
import { useSelector } from 'react-redux'


const columns = [
  {
    field: 'id',
    headerName: 'ID',
    width: 70,
    hide: true,
  },
  {
    field: 'day',
    headerName: 'Day number',
    width: 70,
    sortable: true,
  },
  {
    field: 'hours',
    headerName: 'Hours',
    width: 70,
    sortable: true,
  },
  {
    field: 'task',
    headerName: 'Task',
    width: 300,
    sortable: true,
  },
  {
    field: 'labels',
    headerName: 'Labels',
    width: 300,
    sortable: true,
  },
]

const Report = () => {
  const [report, setReport] = useState([])
  const [start, setStart] = useState(startOfDay(setDate(Date.now(),1)).toISOString())
  const [end, setEnd] = useState(startOfTomorrow(new Date()).toISOString())
  const app = useSelector(state => state.app)

  useEffect(() => {
    reportService.getOTL({ start, end })
      .then(otlReport => {
        let id = 0

        setReport(otlReport
          .map(rl => {
            return { ...rl, id: id++ }
          }))
      })
  }, [start,end,app.filter])

  const handleStartChange = (newValue) => {
    setStart(newValue)
  }

  const handleEndChange = (newValue) => {
    setEnd(newValue)
  }

  return (
    <Box sx={{
      height: 800,
      marginTop: '60px',
    }}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>

        <MobileDatePicker
          margin='2'
          label='Start date'
          inputFormat='dd/MM/yyyy'
          value={start}
          onChange={handleStartChange}
          renderInput={(params) => <TextField {...params} />}
        />
        <MobileDatePicker
          margin='2'
          label='End date'
          inputFormat='dd/MM/yyyy'
          value={end}
          onChange={handleEndChange}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>

      <Box sx={{ flexGrow: 1 }}>
        <DataGrid
          autoHeight
          rows={report}
          columns={columns}
          pageSize={100}
          checkboxSelection
          disableSelectionOnClick
          components={{ Toolbar: GridToolbar }}
        />
      </Box>
    </Box>
  )
}

export default Report