import { ZonedDateTime, convert, nativeJs } from '@js-joda/core'
import axios from 'axios'
import { getConfig } from '../util/config'

const baseUrl = '/api/timer'

const start = async (timer) => {
  const formattedTimer = {
    ...timer,
    start: convert(timer.start).toDate()
  }

  const response = await axios.post(baseUrl + '/start', formattedTimer, getConfig())
  return response.data
}

const stop = async () => {
  const response = await axios.get(baseUrl + '/stop', getConfig())
  return response.data
}

const get = async () => {
  const response = await axios.get(baseUrl, getConfig())
  if (response.data.start) {
    response.data.start = ZonedDateTime.from(nativeJs(new Date(response.data.start)))
  }
  return response.data
}


const timerService = {
  start,
  stop,
  get
}

export default timerService
