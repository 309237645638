import axios from 'axios'
import { getConfig } from '../util/config'

const baseUrl = '/api/timestamps'

const getAll = async () => {
  const response = await axios.get(baseUrl,getConfig())
  return response.data
}

const createNew = async (timestamp) => {
  const response = await axios.post(baseUrl, timestamp,getConfig())
  return response.data
}

export default {
  getAll,
  createNew,
}
