import axios from 'axios'
import { getConfig } from '../util/config'

const baseUrl = '/api/settings'

const get = async () => {
  const response = await axios.get(baseUrl, getConfig())
  return response.data
}

const set = async (settings) => {
  const response = await axios.post(baseUrl, settings, getConfig())
  return response.data
}

export default {
  get,
  set,
}
