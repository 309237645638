import settingsService from '../services/settings'

export const views = {
  timer: 'TIMER',
  report: 'REPORT'
}

export const setView = (view) => {
  return dispatch => {
    dispatch({
      type: 'VIEW',
      view
    })
  }
}

export const setFilter = (filter) => {
  return dispatch => {
    dispatch({
      type: 'FILTER',
      filter
    })
  }
}

const appReducer = (state = { view: views.timer, filter: [] }, action) => {
  switch (action.type) {
  case 'VIEW': {
    return { ...state, view: action.view }
  }
  case 'FILTER': {
    if (JSON.stringify(action.filter) !== JSON.stringify(state.filter))
      settingsService.set({ filter: action.filter })

    return { ...state, filter: action.filter }
  }
  default: return state
  }
}

export default appReducer