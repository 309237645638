
let available = false
let position = {}

const isAvailable = () => {
  if (!available && navigator.geolocation) {
    available = true
    navigator.geolocation.getCurrentPosition(setPosition)
  } else if (!navigator.geolocation) {
    available = false
  }

  return available
}

const setPosition = (curPos) => {
  position = {
    latitude: curPos.coords.latitude,
    longitude: curPos.coords.longitude,
  }
}

const getCurrent = () => {
  return position
}

module.exports = {
  isAvailable,
  getCurrent
}