import taskService from '../services/tasks'

export const addTask = (task) => {
  return async dispatch => {
    const data = await taskService.createNew(task)
    dispatch({
      type: 'ADD',
      data
    })
  }
}

export const initializeTasks = () => {
  return async dispatch => {
    const data = await taskService.getAll()
    dispatch({
      type: 'INIT',
      data
    })
  }
}

const taskReducer = (state = [], action) => {
  switch (action.type) {
  case 'ADD': {
    return state.concat(action.data)
  }
  case 'INIT': {
    return action.data

  }
  default: return state
  }
}

export default taskReducer