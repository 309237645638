import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { startTimer, setTimer, stopTimer } from '../reducers/timerReducer'
import timerService from '../services/timer'
import { Grid, Button } from '@mui/material'
import _ from 'lodash'


const TaskList = () => {
  const dispatch = useDispatch()
  const tasks = useSelector(state => state.tasks)
  const timer = useSelector(state => state.timer)
  const filter = useSelector(state => state.app.filter)

  const handleClick = async (task) => {
    if (timer.task && task.id === timer.task.id) {
      const serverTimer = await timerService.get()

      if (serverTimer.start) {
        dispatch(setTimer(serverTimer))
        dispatch(stopTimer())
      }
    } else {
      dispatch(startTimer(task))
    }
  }

  return (
    <Grid
      sx={{
        marginTop: { xs: 17, sm: 22 },
        padding: 2
      }}
      container
      spacing={1}
      columns={{ xs: 2, sm: 4, md: 12 }}
      justifyContent="center"
      alignItems="center"
    >
      {tasks.map(task =>
        !task.inactive &&
          (filter.length === 0 || _.intersection(task.labels, filter).length > 0)
          ?
          <Grid item key={task.id}>
            <Button
              autoFocus={task.id === tasks[0].id}
              sx={{ width: '11em', height: '5rem' }}
              key={task.id}
              variant={timer.task && timer.task.name === task.name
                ? 'contained'
                : 'outlined'}
              onClick={() => handleClick(task)}
            >
              {task.name}
            </Button>
          </Grid>
          :
          ''
      )}
    </Grid>
  )
}

export default TaskList